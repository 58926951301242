<template>
  <div class="modal-card" style="width: auto; min-width: 300px;">
    <header class="modal-card-head">
      <p class="modal-card-title">Ver PDF resultados</p>
      <button
          type="button"
          class="delete"
          @click="$parent.close()"/>
    </header>
    <section class="modal-card-body">
      <div class="content">
        <div class="columns is-multiline">
          <div class="column is-3-desktop is-2-widescreen" v-for="item in order.results">
            <div class="card" >
              <div class="card-image">
                <figure class="image">
                  <img :src="item.img" :alt="item.title">
                </figure>
              </div>
              <div class="card-content">
                <p class="subtitle">Descargar: <a @click="showPDF=true; url=item.url" target="_blank" type="octet/stream">{{ item.title }}</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <footer class="modal-card-foot">
      <b-button native-type="button" type="is-primary"  @click="$parent.close()">Cerrar</b-button>
    </footer>

    <b-modal v-model="showPDF">
      <iframe class="lapi__iframe" :src="url"></iframe>
    </b-modal>
  </div>

</template>

<script>
export default {
  name: "DownloadLink",
  props: {
        order: Object
  },
  data() {
    return {
      showPDF : false,
      url: ''
    }
  },
  methods: {
  }
}

</script>

<style scoped>
.lapi__iframe{
  width: 100%;
  height: 80vh;
}
</style>
<template>
<div class="modal-card" style="width: auto">
  <header class="modal-card-head">
    <p class="modal-card-title">Observaciones</p>

  </header>
  <section class="modal-card-body">
    <p><strong>Muestra: </strong><span v-if="orderStudy.sample">{{orderStudy.sample.barcode}} {{orderStudy.sample.container.name}} {{orderStudy.sample.bio_material.name}}</span></p>
    <p><strong>Equipo: </strong><span v-if="orderStudy.hardware">{{orderStudy.hardware.num_hardware}} {{orderStudy.hardware.hardware}}</span></p><br>
    <b-field label="Observaciones">
      <b-input maxlength="1000"
               :disabled="readOnly"
               type="textarea" v-model="orderStudy.observations"/>
    </b-field>
    <b-field label="Leyenda 1">
      <b-input
          maxlength="1000" type="textarea"
      disabled v-model="orderStudy.legend1"/>
    </b-field>
    <b-field label="Leyenda 2">
      <b-input
          maxlength="1000" type="textarea"
      disabled v-model="orderStudy.legend2"/>
    </b-field>
    <b-field label="Leyenda 3">
      <b-input
          maxlength="1000" type="textarea"
      disabled v-model="orderStudy.legend3"/>
    </b-field>
  </section>
  <footer class="modal-card-foot">
    <b-button native-type="button" type="is-primary"  @click="$parent.close()">Aceptar</b-button>
  </footer>
</div>
</template>

<script>
export default {
  name: "ObservationsStudyOrder",
  props: {
    orderStudy: Object,
    readOnly: Boolean
  }
}
</script>

<style scoped>

</style>